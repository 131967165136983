// @flow

import React, { Component, Fragment } from "react";
import classNames from "classnames";
import "./style.css";

type Props = {
  isFocused?: ?boolean,
  inputRef?: Function,
  value?: string,
  className: string,
  onChange: Function,
  valid?: boolean,
  isOnlyRussian: boolean,
  onBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void
};

class TextInput extends Component<Props> {
  handleBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.props.onBlur(e);
  };

  handleFocus = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.props.onFocus(e);
  };

  handleChange = (
    e: SyntheticInputEvent<HTMLInputElement>,
    isOnlyRussian: boolean
  ) => {
    if (!/^[а-яёА-ЯЁ0-9 -.\\/]*$/.test(e.target.value) && isOnlyRussian) {
      return;
    }
    this.props.onChange(e);
  };

  render() {
    const {
      isFocused,
      inputRef,
      value,
      className,
      valid,
      onChange,
      onBlur,
      onFocus,
      isOnlyRussian,
      ...other
    } = this.props;

    const classes = classNames("form__input", "input", "input--text", {
      [className]: true,
      "input--valid": valid
    });

    return (
      <Fragment>
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={e => this.handleChange(e, isOnlyRussian)}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          className={classes}
          {...other}
        />
      </Fragment>
    );
  }
  static defaultProps = {
    isOnlyRussian: false,
    className: "input--default"
  };
}

export default TextInput;
