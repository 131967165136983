// @flow

import React, { Component, Fragment } from "react";
import ClickOutside from "react-click-outside";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Input from "../Input";
import YearMonthForm, { fromMonth } from "./YearMonthForm";
import "./style.css";

import dateFnsFormat from "date-fns/format";

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = "DD.MM.YYYY";

const WEEKDAYS_SHORT = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

export const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];

function OverlayComponent({
  dayPickerInputRef,
  showOverlay,
  selectedDay,
  month,
  input,
  classNames,
  tabindex,
  children,
  ...props
}) {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        <ClickOutside
          onClickOutside={() => {
            dayPickerInputRef.hideDayPicker();
          }}
        >
          {children}
        </ClickOutside>
      </div>
    </div>
  );
}

type Props = {
  toMonth: Date,
  isFocused: boolean,
  disabledDays: { after: string }[],
  onChange: Function,
  onBlur: (e?: SyntheticFocusEvent<HTMLInputElement>) => void,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  name: string,
  type: string,
  id: string,
  valid: string,
  placeholder: string
};

type State = {
  month: Date,
  value: ?Date | string,
  showOverlay: boolean
};

export default class DateInput extends Component<Props, State> {
  state = {
    month: this.props.toMonth,
    value: "",
    showOverlay: this.props.isFocused
  };

  dayPickerInputRef = Object;

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextState.value !== this.state.value ||
      nextState.month !== this.state.month ||
      nextProps.valid !== this.props.valid
    );
  }

  onDayPickerChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const matches: ?(any[]) = e.target.value.match(/(\d{2})\.(\d{2})\.(\d{4})/);

    if (!!e.target.value && matches) {
      const selectedDate = new Date(
        matches[3],
        Number(matches[2]) - 1,
        matches[1]
      );

      this.props.onChange(selectedDate);
      this.setState({ value: selectedDate });
      this.handleYearMonthChange(selectedDate);
    } else {
      this.props.onChange("non-date");
      this.setState({ value: e.target.value });
    }
  };

  handleYearMonthChange = (month: Date) => {
    this.setState({ month });
  };

  render() {
    const { onChange, onBlur, onFocus, name, type, id, valid } = this.props;

    return (
      <Fragment>
        {type === "date" ? (
          <Input
            type={type}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={e =>
              onChange({ target: { value: e.target.valueAsDate } })
            }
            name={name}
            id={id}
          />
        ) : (
          <DayPickerInput
            overlayComponent={props => (
              <OverlayComponent
                {...props}
                dayPickerInputRef={this.dayPickerInputRef}
                showOverlay={this.state.showOverlay}
              />
            )}
            ref={ref => (this.dayPickerInputRef = ref)}
            keepFocus={false}
            value={this.state.value}
            formatDate={formatDate}
            format={FORMAT}
            placeholder={this.props.placeholder}
            dayPickerProps={{
              className: "asd",
              onFocus: e => {
                this.setState({ showOverlay: true });
                onFocus(e);
              },
              onBlur: e => {
                this.setState({ showOverlay: false });
                onBlur(e);
              },
              disabledDays: this.props.disabledDays || [],
              selectedDays:
                this.state.value instanceof Date ? this.state.value : null,
              locale: "ru",
              months: MONTHS,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
              month: this.state.month,
              fromMonth: fromMonth,
              toMonth: this.props.toMonth,
              captionElement: ({ date, localeUtils }) => (
                <YearMonthForm
                  date={date}
                  localeUtils={localeUtils}
                  onChange={this.handleYearMonthChange}
                  toMonth={this.props.toMonth}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              )
            }}
            onDayChange={date => {
              if (date !== undefined) {
                onChange(date ? date : "non-date");
                onBlur();
              }
            }}
            component={Input}
            inputProps={{
              valid,
              onFocus,
              onBlur,
              name,
              type,
              id,
              onChange: this.onDayPickerChange
            }}
          />
        )}
      </Fragment>
    );
  }
}
