// @flow

import React, { Component } from "react";
import "./style.css";

type Props = {
  percent: string | number,
  text: string,
  mode: string
};

class Progress extends Component<Props> {
  render() {
    return (
      <div className="lcform__progressbar progressbar progressbar--mfr">
        <i className="progressbar__percentage">{this.props.percent}% </i>
        {this.props.mode === "multiline" && <br />}
        <span className="progressbar__prediction">{this.props.text}</span>
      </div>
    );
  }
}
export default Progress;
