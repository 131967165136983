// @flow

import React, { Component, Fragment } from "react";
import Progressbar from "./Progressbar";
import {
  EMAIL_STEP,
  EXTENDED_STEP,
  MAIN_STEP,
  RANGE_FIVE,
  RANGE_FOUR,
  RANGE_ONE,
  RANGE_SEVEN,
  RANGE_SIX,
  RANGE_THREE,
  RANGE_TWO
} from "../../utils/consts";

const stringFormatter = (str: string) => {
  return str.replace(/\s+/g, "");
};

const getProgressBarValuesByAmount = (amount: string) => {
  const amountToNumber = Number(amount);
  if (amountToNumber <= 7000) {
    return { percent: RANGE_ONE, text: "Автоматическое одобрение" };
  } else if (amountToNumber > 7000 && amountToNumber < 15999) {
    return { percent: RANGE_TWO, text: "Может понадобиться паспорт" };
  } else if (amountToNumber > 15999 && amountToNumber <= 34999) {
    return { percent: RANGE_THREE, text: "Нужен только паспорт" };
  } else if (amountToNumber > 34999 && amountToNumber <= 54999) {
    return {
      percent: RANGE_FOUR,
      text: "Может понадобиться подтверждение места работы"
    };
  } else if (amountToNumber > 54999 && amountToNumber <= 109999) {
    return {
      percent: RANGE_FIVE,
      text: "Может понадобиться справка о доходах или залог"
    };
  } else if (amountToNumber > 109999 && amountToNumber <= 324999) {
    return {
      percent: RANGE_SIX,
      text: "Необходима справка о доходах или залог"
    };
  } else if (amountToNumber >= 325000) {
    return {
      percent: RANGE_SEVEN,
      text: "Нужна справка 2-НДФЛ или залог"
    };
  } else {
    return { percent: RANGE_ONE, text: "Автоматическое одобрение" };
  }
};

const getProgressBarValues = (step: number, amount: string) => {
  if (step === MAIN_STEP) {
    return getProgressBarValuesByAmount(stringFormatter(amount.toString()));
  } else if (step === EMAIL_STEP) {
    return { percent: "+20", text: "к одобрению за подтверждение почты" };
  } else if (step === EXTENDED_STEP) {
    return {
      percent: "+25",
      text: "к одобрению за добавление Вашего Удостоверения личности и прописки"
    };
  } else {
    return { percent: "", text: "" };
  }
};

const hasProgressbar = step => {
  return step === MAIN_STEP || step === EMAIL_STEP || step === EXTENDED_STEP;
};

const enhanceText = text => `Вероятность одобрения – ${text}`;

type Props = {
  step: number,
  amount: string
};

class Progress extends Component<Props> {
  render() {
    const { step, amount } = this.props;
    const progressValues = getProgressBarValues(step, amount);

    return (
      <Fragment>
        {hasProgressbar(step) && (
          <Progressbar
            mode={step === MAIN_STEP ? "multiline" : "oneline"}
            text={progressValues.text}
            percent={
              step === MAIN_STEP
                ? enhanceText(progressValues.percent)
                : progressValues.percent
            }
          />
        )}
      </Fragment>
    );
  }
}
export default Progress;
