// @flow

import React from "react";
import "./style.css";

const getLogoUrl = img => `https://credithub.ru/storage/${img}`;

function resolveLinkDeps({ parentOrigin, title, webmasterId, offer_link }) {
  const resolvers = {
    host:
      parentOrigin.replace(/^https?:\/\//, "").replace(/\/$/, "") ||
      "seqvoya.com",
    campaign: "seqvoya_form",
    medium: "seqvoya_form",
    offer_slug: title,
    wmid: webmasterId || 10863
  };

  return offer_link.replace(/{{\$(\w+)}}/g, function(match, first) {
    return resolvers[first] ? resolvers[first] : "seqvoya_default";
  });
}

function addUtm(url, { parentOrigin }) {
  const resolvers = {
    host:
      parentOrigin.replace(/^https?:\/\//, "").replace(/\/$/, "") ||
      "seqvoya.com",
    campaign: "seqvoya_form",
    medium: "seqvoya_form"
  };
  const utmUrl = `utm_source=${resolvers.host}&utm_medium=${
    resolvers.medium
  }&utm_campaign=${resolvers.campaign}`;
  const separator = url.indexOf("?") === -1 ? "?" : "&";
  if (
    url.indexOf("&utm_source=") === -1 &&
    url.indexOf("?utm_source=") === -1
  ) {
    return `${url}${separator}${utmUrl}`;
  }
  return url;
}

type Props = {
  img: string,
  title: string,
  title_ru: string,
  amount_human: string,
  percentage_human: string,
  processing_time_human: string,
  link: string,
  offer_link: string,
  webmasterId: string,
  parentOrigin: string,
  onReachGoal: (goal: string) => void
};

export default (props: Props) => (
  <div className="list__item item">
    <div className="item__logo">
      <img src={getLogoUrl(props.img)} alt="" />
    </div>
    <h4 className="col__header">{props.title_ru}</h4>
    <div className="item__data data">
      <div className="data__col col col--left">
        <span className="col__title">Сумма</span>
        <span className="col__value value value--bold">
          {props.amount_human}
        </span>
      </div>
      <div className="data__col col col--center">
        <span className="col__title">Процент</span>
        <span className="col__value value value--bold">
          {props.percentage_human}
        </span>
      </div>
      <div className="data__col col col--right">
        <span className="col__title">Рассмотрение</span>
        <span className="col__value value">{props.processing_time_human}</span>
      </div>
      <div className="data__col col col--btn">
        <a
          href={addUtm(resolveLinkDeps(props), props)}
          rel="noreferrer noopener"
          target="_blank"
          className="col__action btn btn--success btn--feed"
          onClick={() => props.onReachGoal("step4-offer")}
        >
          Получить
        </a>
      </div>
    </div>
  </div>
);
