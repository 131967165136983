// @flow

import axios from "axios";

import { getApiUrl } from "./utils.js";
import { extendedStepTransformer } from "../transformers/extenededForm";

import type { MainData, UserComponents } from "../types/app";
import type { Values } from "../types/extendedForm";

export default {
  main: {
    create: (
      data: MainData,
      {
        userHash,
        userComponents,
        parentOrigin,
        referrer,
        webmasterId,
        yandexClientId
      }: {
        userHash: string,
        userComponents: UserComponents[],
        parentOrigin: string,
        referrer: string,
        webmasterId: string,
        yandexClientId: string
      }
    ) =>
      axios
        .post(`${getApiUrl()}/l/main`, {
          ...data,
          user_hash: userHash,
          user_components: userComponents,
          parent_origin: parentOrigin,
          referrer,
          webmaster_id: webmasterId,
          yandex_client_id: yandexClientId
        })
        .then(response => response.data)
  },
  extended: {
    create: (
      uuid: string,
      data: Values,
      userHash: string,
      userComponents: UserComponents[]
    ) =>
      axios
        .post(`${getApiUrl()}/l/extended`, {
          uuid,
          ...extendedStepTransformer(data),
          user_hash: userHash,
          user_components: userComponents
        })
        .then(response => response.data)
  },
  submit: {
    create: (uuid: string) =>
      axios
        .post(`${getApiUrl()}/l/submit`, { uuid })
        .then(response => response.data)
  },
  feeds: {
    all: () => axios.get(`${getApiUrl()}/feeds`).then(response => response.data)
  },
  suggestions: {
    names: (term: string, parts: string[]) =>
      axios
        .get(`${getApiUrl()}/suggestions/names`, { params: { term, parts } })
        .then(response => response.data)
        .then(data =>
          data.map(item => ({ value: item.value, label: item.value }))
        ),
    emails: (term: string) =>
      axios
        .get(`${getApiUrl()}/suggestions/emails`, { params: { term } })
        .then(response => response.data)
        .then(data =>
          data.map(item => ({ value: item.value, label: item.value }))
        )
  },
  user: {
    get: (uuid: string) =>
      axios.get(`${getApiUrl()}/user`, { params: { uuid } })
  },
  statistics: {
    post: (params: Object) =>
      axios
        .post(`${getApiUrl()}/stats/default`, params)
        .then(response => response.data)
  }
};
