// @flow

export const MAIN_STEP = 1;
export const EMAIL_STEP = 2;
export const EXTENDED_STEP = 3;
export const THANK_YOU_STEP = 4;
export const RANGE_ONE = 97;
export const RANGE_TWO = 94;
export const RANGE_THREE = 84;
export const RANGE_FOUR = 72;
export const RANGE_FIVE = 64;
export const RANGE_SIX = 51;
export const RANGE_SEVEN = 37;
