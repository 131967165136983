// @flow

import React, { Component } from "react";
import classNames from "classnames";
import "./style.css";

type Props = {
  children: string,
  submit?: boolean,
  success?: boolean,
  innactive?: boolean,
  other?: Object
};

class Submit extends Component<Props> {
  render() {
    const { children, submit, success, innactive, ...other } = this.props;

    const type = submit ? "submit" : "button";

    const classes = classNames({
      form__btn: true,
      btn: true,
      "btn--success": success,
      "btn--innactive": innactive
    });

    return (
      <button className={classes} type={type} {...other}>
        {children}
      </button>
    );
  }
}

export default Submit;
