// @flow

import { format } from "date-fns";

import type { Values } from "../types/extendedForm";

export const extendedStepTransformer = (data: Values) => ({
  ...data,
  birth_date: format(data.birth_date, "YYYY-MM-DD"),
  passport_date: format(data.passport_date, "YYYY-MM-DD")
});

export const dummy = 1;
