// @flow

import email from "validator/lib/isEmail";
import url from "validator/lib/isURL";
import number from "validator/lib/isDecimal";
import length from "validator/lib/isLength";
import mobilePhone from "validator/lib/isMobilePhone";
import isAfter from "date-fns/is_after";
import subYears from "date-fns/sub_years";

export default class Validator {
  values = Object;
  errors = Object;
  rawErrors = Object;

  constructor(values: Object) {
    this.values = values;
    this.errors = {};
    this.rawErrors = {};
  }

  static create(values: Object) {
    return new Validator(values);
  }

  validate = (validationFields: Object, validator: Function) => {
    const fields = validationFields;

    Object.keys(fields).forEach(field => {
      if (this.errors[field]) {
        return;
      }

      const message = fields[field];

      const isValid = validator(this.values[field]);

      if (!isValid) {
        this.errors = {
          ...this.errors,
          [field]: message
        };
      }
    });

    return this;
  };
}

export const isRequired = (value: string) => !!value;
isRequired.constant = "REQUIRED";

export const isEmail = (value: string) => email(`${value}`);
isEmail.constant = "INVALID_EMAIL";

export const isNumber = (value: string) => number(`${value}`);
isNumber.constant = "MUST_BE_NUMERIC";

export const isDate = (value: string) =>
  !!value && value instanceof Date && !isNaN(value.valueOf());
isDate.constant = "MUST_BE_DATE";

export const isAdult = (value: string) =>
  isAfter(subYears(new Date(), 18), value);

export const isPassportSeriaNum = (value: string) =>
  /^\d{4}-\d{6}$/.test(value);

export const isPassportKod = (value: string) => /^\d{3}-\d{3}$/.test(value);

isAdult.constant = "DATE_MUST_BE_BEFORE_OR_EQUAL__-18_YEARS";

export const isPhone = (value: string) => mobilePhone(`${value}`);
isPhone.constant = "MUST_BE_PHONE_NUMBER";

export const isLength = (value: string) =>
  length(`${value}`, {
    min: 0,
    max: 3000
  });
isLength.constant = "TOO_BIG_STRING__3000";

export const isURL = (value: string) =>
  url(`${value}`, {
    protocols: ["http", "https", "ftp"],
    require_protocol: true
  });
isURL.constant = "INVALID_URL";

export const isEqual = (secondField: string) => {
  function isEqualInner(firstField: string) {
    return firstField === secondField;
  }
  isEqualInner.constant = "The password and password confirmation must match.";

  return isEqualInner;
};
