// @flow

import React, { Component } from "react";
import classNames from "classnames";
import checkIcon from "./img/checked.svg";
import "./style.css";

type Props = {
  onChange: Function,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  className: string,
  value: boolean,
  valid: boolean,
  error: ?boolean,
  other?: Object
};

class CheckboxInput extends Component<Props> {
  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) =>
    this.props.onChange(e);

  render() {
    const { className, value, valid, error, ...other } = this.props;
    const classes = classNames("form__input", "input", "input--checkbox", {
      [className]: true
    });

    const wrapperClasses = classNames("checkbox", "checkbox--customs", {
      "checkbox--error": error,
      "checkbox--valid": valid
    });

    return (
      <div className={wrapperClasses}>
        <input
          type="checkbox"
          className={classes}
          {...other}
          onChange={this.handleChange}
          value={value}
        />
        <div className="checkbox__icon">
          <img src={checkIcon} alt={checkIcon} />
        </div>
      </div>
    );
  }

  static defaultProps = {
    className: "checkbox--default"
  };
}

export default CheckboxInput;
