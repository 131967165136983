// @flow

import React, { Component } from "react";
import { EMAIL_STEP, EXTENDED_STEP, MAIN_STEP } from "../../utils/consts";

const getTitles = step => {
  switch (step) {
    case MAIN_STEP:
      return {
        title: "Заявка на кредит",
        subtitle: null
      };
    case EMAIL_STEP:
      return {
        title: "Подтверждение email-адреса",
        subtitle: "Вы можете пропустить этот шаг и продолжить заполнение заявки"
      };
    case EXTENDED_STEP:
      return {
        title: "Информация о заемщике",
        subtitle: "Дополнительные данные увеличат шансы на получение кредита"
      };
    default:
      return {
        title: "ЗАЯВКА НА ПОЛУЧЕНИЕ",
        subtitle: "Отправьте заявку сейчас — получите деньги сегодня!"
      };
  }
};

type Props = {
  step: number
};

class Header extends Component<Props> {
  render() {
    const { title, subtitle } = getTitles(this.props.step);

    return (
      <div className="lcform__header header">
        <h2 className="header__title">{title}</h2>
        {subtitle && <span className="header__subtitle">{subtitle}</span>}
      </div>
    );
  }
}

export default Header;
