// @flow

import React, { Component } from "react";
import Select from "react-select/lib/Async";
import { components } from "react-select";
import classNames from "classnames";
import Input from "../Input";
import "./style.css";

import type { Meta, Suggestions } from "../../types/autocompleteInput";

const customStyles = {
  option: (base, state) => ({
    ...base,
    borderBottom: "none"
  }),
  control: () => ({
    border: "none"
  })
};

type Props = {
  onFocus: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: ({ [string]: { value: any } }) => void,
  loadingMessage: ?Meta,
  emptyMessage: ?Meta,
  noOptionsMessage: ?Meta,
  isOnlyRussian: boolean,
  fetchSource: Function,
  valid: boolean,
  maxSuggestions: number
};

type State = {
  selectedOption: null,
  inputValue: string,
  suggestions: Suggestions[],
  isFocused: boolean,
  focusedLabel: ?string
};

class AutocompleteInput extends Component<Props, State> {
  static defaultProps = {
    maxSuggestions: 5
  };

  state = {
    selectedOption: null,
    inputValue: "",
    suggestions: [],
    isFocused: false,
    focusedLabel: null
  };

  timer: TimeoutID = setTimeout(() => {}, 500);

  handleFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onFocus(e);
    this.setState({ isFocused: true });
  };

  handleBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onBlur(e);
    this.setState({ isFocused: false, focusedLabel: null });
  };

  handleChange = (e: Suggestions, meta: Meta) => {
    this.props.onChange({ target: { value: e.value } });

    if (meta.action === "select-option") {
      this.setState({ inputValue: e.value });
    }
  };

  handleInputChange = (inputValue: string, meta: Meta) => {
    const { action } = meta;

    switch (action) {
      case "input-change":
        this.setState({ inputValue });
        this.props.onChange({ target: { value: inputValue } });
        return;
      case "set-value":
        if (inputValue.length > 0) {
          this.setState({ inputValue });
        }
        return;
      default:
        return;
    }
  };

  loadOptions = (
    inputValue: string,
    callback: (suggestions: Suggestions[]) => void
  ) => {
    if (inputValue) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        if (inputValue.trim().length === 0) {
          this.setState({ suggestions: [] });
          callback([]);
          return;
        }

        this.props
          .fetchSource(inputValue)
          .then(suggestions => suggestions.slice(0, this.props.maxSuggestions))
          .then(suggestions => {
            this.setState({ suggestions });
            callback(suggestions);
          })
          .catch(() => {
            this.setState({ suggestions: [] });
            callback([]);
          });
      }, 500);
    }
  };

  inputComponent = ({
    innerRef,
    value,
    onChange,
    onBlur,
    onFocus,
    id,
    type,
    className
  }: {
    innerRef: Function,
    value: string,
    onChange: Function,
    onBlur: Function,
    onFocus: Function,
    id: string,
    type: string,
    className: null
  }) => {
    const classes = classNames(className, "shadow-input__input");
    const { isOnlyRussian } = this.props;

    const hasPlaceholder =
      this.state.suggestions.length > 0 &&
      this.state.suggestions[0].label.trim() !== value.trim();

    let suggest;

    if (this.state.suggestions.length > 0) {
      suggest = this.state.focusedLabel
        ? this.state.focusedLabel
        : this.state.suggestions[0].label;
    } else {
      suggest = "";
    }

    let formattedPlaceholder;
    if (hasPlaceholder) {
      try {
        formattedPlaceholder = suggest.replace(
          new RegExp(`^${value}`, "gi"),
          str => `<span class="shadow-input__hide-text">${str}</span>`
        );
      } catch (e) {
        formattedPlaceholder = null;
      }
    }
    const hasFormattedSpan =
      formattedPlaceholder && formattedPlaceholder.indexOf("<span") >= 0;

    return (
      <div className="shadow-input">
        {this.state.isFocused &&
          this.state.inputValue &&
          this.state.inputValue.length > 0 &&
          hasPlaceholder &&
          formattedPlaceholder &&
          hasFormattedSpan && (
            <div
              className="shadow-input__background"
              dangerouslySetInnerHTML={{
                __html: formattedPlaceholder
              }}
            />
          )}
        <Input
          inputRef={innerRef}
          className={classes}
          id={id}
          type={type}
          value={value}
          isOnlyRussian={isOnlyRussian}
          valid={this.props.valid}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    );
  };

  optionComponent = ({ isFocused, children, ...props }: Object) => {
    // TODO: анти-паттерн
    isFocused &&
      this.state.focusedLabel !== children &&
      this.setState({ focusedLabel: children });

    return (
      <components.Option {...props} isFocused={isFocused}>
        {children}
      </components.Option>
    );
  };

  render() {
    const { loadingMessage, emptyMessage, noOptionsMessage } = this.props;

    return (
      <Select
        className="autocomplete-input"
        {...this.props}
        classNamePrefix="select"
        isClearable
        isSearchable
        onInputChange={this.handleInputChange}
        inputValue={this.state.inputValue}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        loadOptions={this.loadOptions}
        components={{
          Input: this.inputComponent,
          Option: this.optionComponent
        }}
        loadingMessage={({ inputValue }) =>
          inputValue
            ? loadingMessage || "Загрузка.."
            : emptyMessage || "Начните вводить имя"
        }
        noOptionsMessage={() => noOptionsMessage || "Ничего не найдено"}
        styles={customStyles}
      />
    );
  }
}

export default AutocompleteInput;
