// @flow

import React, { Component, Fragment } from "react";
import classNames from "classnames";
import Button from "../../components/Button/index";
import "./style.css";
import emailImage from "./img/email.png";
import { EXTENDED_STEP } from "../../utils/consts";
import api from "../../api";

import type { mainData } from "../../types/app";

const getMailAgent = str => {
  return str.split("@")[1];
};

type Props = {
  onReachGoal: (goal: string) => void,
  mainData: mainData,
  uuid: string,
  onChangeStep: Function,
  headerIsVisible: boolean
};

const goalByType = (type: string) => {
  switch (type) {
    case "skip":
      return "step2-skip";
    case "checkemail":
      return "step2-checkemail";
    default:
      return "step2-skip";
  }
};

class EmailSubmit extends Component<Props, {}> {
  handleNextStep = (type: string) => async () => {
    try {
      await api.submit.create(this.props.uuid);
      this.props.onReachGoal(goalByType(type));
    } catch (error) {
      return error.response.data.errors;
    }

    this.props.onChangeStep(EXTENDED_STEP);
  };

  render() {
    const { mainData } = this.props;
    const { email } = mainData;
    const strEmail = String(email);
    const classes = classNames("lcform__form", "form", "form--container", {
      "lcform__form--with-header": this.props.headerIsVisible
    });
    return (
      <Fragment>
        <div className={classes}>
          <form className="form__step step">
            <div className="form__image">
              <img src={emailImage} alt={emailImage} />
            </div>
            <p className="paragraph">
              Для идентификации, пожалуйста, подтвердите свой email-адрес.{" "}
              <br />
              Письмо отправлено на почту <strong>{email}</strong>
            </p>
            <div className="step__row row row--footer row--nowrap row--thxpage">
              <div className="input__group group group--stepback">
                <Button innactive onClick={this.handleNextStep("skip")}>
                  Пропустить
                </Button>
              </div>
              <div className="input__group group group--button">
                <a
                  href={"//" + getMailAgent(strEmail)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form__btn btn btn--success"
                  onClick={this.handleNextStep("checkemail")}
                >
                  Подтвердить почту
                </a>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default EmailSubmit;
