// @flow

import React, { Component, Fragment } from "react";
import classNames from "classnames";
import startOfToday from "date-fns/start_of_today";
import getDay from "date-fns/get_day";
import getMonth from "date-fns/get_month";
import getYear from "date-fns/get_year";
import { Field, Form } from "react-final-form";
import Button from "../../components/Button/index";
import InputGroup from "../../components/InputGroup/index";
import Input from "../../components/Input/index";
import DateInput from "../../components/DateInput/index";
import RadioInputGroup from "../../components/RadioGroup/index";
import "./style.css";
import Validator, {
  isDate,
  isRequired,
  isAdult,
  isPassportSeriaNum,
  isPassportKod
} from "../../utils/Validator";
import { THANK_YOU_STEP } from "../../utils/consts";
import { errorMessage, isError } from "../../utils/form";
import api from "../../api";
import MaskedInput from "../../components/MaskedInput";
import { deleteUuid } from "../../utils/uuid";
import { Values } from "../../types/extendedForm";
import { UserComponents } from "../../types/app";

const date = startOfToday();
const currentMonth = getMonth(date);
const currentDay = date.getDate();
const getAdulthoodDate = () =>
  new Date(getYear(date) - 18, currentMonth, currentDay);

type Props = {
  uuid: string,
  userHash: string,
  userComponents: UserComponents[],
  onChangeStep: Function,
  onChangeHeight: Function,
  onReachGoal: (goal: string) => void,
  headerIsVisible: boolean,
  values: Values,
  isMobile: () => boolean
};
class ExtendedForm extends Component<Props> {
  handleSubmit = async (values: Values) => {
    try {
      await api.extended.create(
        this.props.uuid,
        values,
        this.props.userHash,
        this.props.userComponents
      );

      this.props.onChangeStep(THANK_YOU_STEP);
      this.props.onReachGoal("step3");
      deleteUuid();
    } catch (error) {
      return error.response.data.errors;
    }
  };

  handleValidate = (values: Values) => {
    const validator = Validator.create(values)
      .validate(
        {
          passport_seria_num: "Укажите серию и номер паспорта",
          birth_date: "Укажите дату рождения",
          birth_place: "Укажите место рождения",
          passport_kod: "Укажите код подразделения",
          passport_date: "Укажите дату выдачи",
          passport_issued: "Укажите кем выдан",
          registration_region: "Укажите регион прописки",
          registration_city: "Укажите город прописки",
          registration_street: "Укажите улицу прописки",
          registration_housenum: "Укажите дом прописки",
          real_placement_differs: "Совпадает с адресом постоянной регистрации?"
        },
        isRequired
      )
      .validate(
        {
          birth_date: "Выберите дату рождения в календаре",
          passport_date: "Выберите дату выдачи в календаре"
        },
        isDate
      )
      .validate(
        {
          birth_date: "Вам должно быть больше 18 лет"
        },
        isAdult
      )
      .validate(
        {
          passport_seria_num: "Заполни серию и номер паспорта"
        },
        isPassportSeriaNum
      )
      .validate(
        {
          passport_kod: "Заполни код подразделения"
        },
        isPassportKod
      );

    if (values.real_placement_differs === "1") {
      validator.validate(
        {
          real_region: "Укажите регион проживания",
          real_city: "Укажите город проживания",
          real_street: "Укажите улицу проживания",
          real_housenum: "Укажите дом проживания"
        },
        isRequired
      );
    }

    this.props.onChangeHeight();

    return validator.errors;
  };

  renderRealAddress = () => (
    <Fragment>
      <div className="step__row row">
        <Field name="real_region">
          {({ input, meta }) => (
            <InputGroup
              component={Input}
              label="Регион"
              isOnlyRussian
              id="real_region"
              valid={meta.touched && meta.valid}
              error={isError(meta) ? errorMessage(meta) : null}
              {...input}
            />
          )}
        </Field>
        <Field name="real_city">
          {({ input, meta }) => (
            <InputGroup
              component={Input}
              label="Город"
              isOnlyRussian
              id="real_city"
              valid={meta.touched && meta.valid}
              error={isError(meta) ? errorMessage(meta) : null}
              {...input}
            />
          )}
        </Field>
      </div>
      <div className="step__row row">
        <Field name="real_street">
          {({ input, meta }) => (
            <InputGroup
              component={Input}
              label="Улица"
              isOnlyRussian
              id="real_street"
              valid={meta.touched && meta.valid}
              error={isError(meta) ? errorMessage(meta) : null}
              {...input}
            />
          )}
        </Field>
        <Field name="real_housenum">
          {({ input, meta }) => (
            <InputGroup
              component={Input}
              label="Дом"
              id="real_housenum"
              isOnlyRussian
              valid={meta.touched && meta.valid}
              error={isError(meta) ? errorMessage(meta) : null}
              {...input}
            />
          )}
        </Field>
      </div>
    </Fragment>
  );

  render() {
    const classes = classNames("lcform__form", "form", "form--container", {
      "lcform__form--with-header": this.props.headerIsVisible
    });

    return (
      <Fragment>
        <div className={classes} data-role="page">
          <Form
            initialValues={{ real_placement_differs: "0" }}
            onSubmit={this.handleSubmit}
            validate={this.handleValidate}
            render={({
              handleSubmit,
              submitting,
              pristine,
              invalid,
              values
            }: {
              handleSubmit: Function,
              pristine?: boolean,
              invalid?: boolean,
              submitting?: boolean,
              values: Values
            }) => (
              <form
                className="form__step step"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="step__row row">
                  <Field name="passport_seria_num">
                    {({ input, meta }) => (
                      <InputGroup
                        component={MaskedInput}
                        label="Серия и номер паспорта"
                        id="passport_seria_num"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        placeholder="1000-300000"
                        pattern="[0-9\s\-]*"
                        inputMode={this.props.isMobile() ? "numeric" : "text"}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="birth_date">
                    {({ input, meta }) => (
                      <InputGroup
                        component={DateInput}
                        label="Дата рождения"
                        id="birth_date"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        type={this.props.isMobile() ? "date" : "text"}
                        toMonth={getAdulthoodDate()}
                        disabledDays={[
                          {
                            after: getAdulthoodDate()
                          }
                        ]}
                        placeholder="27.09.1980"
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <Field name="birth_place">
                  {({ input, meta }) => (
                    <InputGroup
                      component={Input}
                      label="Место рождения"
                      placeholder="г. Москва"
                      isOnlyRussian
                      id="birth_place"
                      valid={meta.touched && meta.valid}
                      error={isError(meta) ? errorMessage(meta) : null}
                      {...input}
                    />
                  )}
                </Field>
                <div className="step__row row">
                  <Field name="passport_kod">
                    {({ input, meta }) => (
                      <InputGroup
                        component={MaskedInput}
                        label="Код подразделения"
                        id="passport_kod"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        placeholder="100-300"
                        mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                        pattern="[0-9\-]*"
                        inputMode={this.props.isMobile() ? "numeric" : "text"}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="passport_date">
                    {({ input, meta }) => (
                      <InputGroup
                        component={DateInput}
                        label="Дата выдачи"
                        id="passport_date"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        type={this.props.isMobile() ? "date" : "text"}
                        toMonth={new Date()}
                        placeholder="28.09.2000"
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <Field name="passport_issued">
                  {({ input, meta }) => (
                    <InputGroup
                      component={Input}
                      label="Кем выдан"
                      isOnlyRussian
                      placeholder="Отделом УФМС России по гор. Москве"
                      id="passport_issued"
                      valid={meta.touched && meta.valid}
                      error={isError(meta) ? errorMessage(meta) : null}
                      {...input}
                    />
                  )}
                </Field>
                <h2 className="form__subtitle">
                  Адрес по месту постоянной регистрации
                </h2>
                <div className="step__row row">
                  <Field name="registration_region">
                    {({ input, meta }) => (
                      <InputGroup
                        component={Input}
                        label="Регион"
                        isOnlyRussian
                        id="registration_region"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="registration_city">
                    {({ input, meta }) => (
                      <InputGroup
                        component={Input}
                        label="Город"
                        isOnlyRussian
                        id="registration_city"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="step__row row">
                  <Field name="registration_street">
                    {({ input, meta }) => (
                      <InputGroup
                        component={Input}
                        label="Улица"
                        isOnlyRussian
                        id="registration_street"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="registration_housenum">
                    {({ input, meta }) => (
                      <InputGroup
                        component={Input}
                        label="Дом"
                        isOnlyRussian
                        id="registration_housenum"
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <h2 className="form__subtitle">
                  Адрес фактического проживания
                </h2>
                <div className="step__row row row--radio">
                  <div className="input__group group group--radio">
                    <Field name="real_placement_differs" type="radio" value="1">
                      {({ input, meta }) => (
                        <label
                          className={`group__label ${
                            isError(meta) ? "group__label--error" : ""
                          }`}
                        >
                          Совпадает с адресом постоянной регистрации
                        </label>
                      )}
                    </Field>
                  </div>
                  <div className="input__group group group--radio">
                    <div className="radioSet">
                      <Field
                        name="real_placement_differs"
                        type="radio"
                        value="0"
                      >
                        {({ input, meta }) => (
                          <Fragment>
                            <RadioInputGroup
                              id="address_yes"
                              valid={meta.touched && meta.valid}
                              error={isError(meta) ? errorMessage(meta) : null}
                              {...input}
                            />
                            <span className="radioSet__mask" />
                            <label htmlFor="address_yes">Да</label>
                          </Fragment>
                        )}
                      </Field>
                    </div>
                    <div className="radioSet">
                      <Field
                        name="real_placement_differs"
                        type="radio"
                        value="1"
                      >
                        {({ input, meta }) => (
                          <Fragment>
                            <RadioInputGroup
                              id="address_no"
                              valid={meta.touched && meta.valid}
                              error={isError(meta) ? errorMessage(meta) : null}
                              {...input}
                            />
                            <span className="radioSet__mask" />
                            <label htmlFor="address_no">Нет</label>
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                {values.real_placement_differs === "1" &&
                  this.renderRealAddress()}
                <div className="step__row row row--footer">
                  <div
                    className="input__group group group--button"
                    style={{ width: "100%" }}
                  >
                    <Button submit success disabled={submitting}>
                      Далее
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Fragment>
    );
  }
}

export default ExtendedForm;
