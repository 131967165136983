// @flow
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import api from "../../api/index";
import FeedItem from "../../components/FeedItem/index";
import { deleteUuid } from "../../utils/uuid";
import "./style.css";
import thx from "./img/thx.png";

import type { Feeds } from "../../types/thankYouPage";

type Props = {
  onChangeHeight: () => void,
  onReachGoal: (goal: string) => void,
  headerIsVisible: boolean,
  parentOrigin: string,
  webmasterId: string
};

type State = {
  isLoading: boolean,
  isError: boolean,
  feeds: Feeds[]
};

class Thanks extends Component<Props, State> {
  state = {
    isLoading: true,
    feeds: [],
    isError: false
  };

  componentDidMount() {
    api.feeds.all().then(
      feeds => {
        this.setState({ feeds, isLoading: false });
        this.props.onChangeHeight();
      },
      () => {
        this.setState({ isLoading: false, isError: true });
        this.props.onChangeHeight();
      }
    );
    deleteUuid();
  }

  renderFeedError = () => <div>Ошибка</div>;

  renderFeedLoading = () => <div>Загрузка..</div>;

  renderFeed = () => {
    if (this.state.isError) {
      return this.renderFeedError();
    }

    if (this.state.isLoading) {
      return this.renderFeedLoading();
    }

    if (this.state.feeds.length > 0) {
      return (
        <div className="feed__list list">
          {this.state.feeds.map(feedItem => (
            <FeedItem
              key={feedItem.title}
              onReachGoal={this.props.onReachGoal}
              parentOrigin={this.props.parentOrigin}
              webmasterId={this.props.webmasterId}
              {...feedItem}
            />
          ))}
        </div>
      );
    }
  };

  render() {
    const classes = classNames("lcform__form", "form", "form--container", {
      "lcform__form--with-header": this.props.headerIsVisible
    });

    return (
      <Fragment>
        <div className={classes}>
          <form className="form__step step">
            <div className="form__image image image--media image--thanks">
              <img src={thx} alt="" />
            </div>
            <h2 className="step__headding step--thankyou">
              Остался всего 1 шаг до получения займа!
            </h2>
            <p className="paragraph">
              Войдите в почту и подтвердите вашу заявку.
            </p>
          </form>
          <div className="feed_header">
            <p>Выгодные предложения от наших партнеров:</p>
          </div>
          {this.renderFeed()}
        </div>
      </Fragment>
    );
  }
}

export default Thanks;
