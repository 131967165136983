// @flow

import React from "react";
import { MONTHS } from "./index";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

export const fromMonth = new Date(currentYear - 80, currentMonth);

function YearMonthForm({
  date,
  localeUtils,
  onChange,
  toMonth,
  onFocus,
  onBlur
}: {
  date: Date,
  localeUtils: Object,
  onChange: Function,
  toMonth: Date,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  onBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => void
}) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption" role="heading">
      <select
        className="DayPicker-select"
        onFocus={onFocus}
        onBlur={onBlur}
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {MONTHS[i]}
          </option>
        ))}
      </select>
      <select
        className="DayPicker-select DayPicker-select--yeared"
        onFocus={onFocus}
        onBlur={onBlur}
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default YearMonthForm;
