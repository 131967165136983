// @flow

import React from "react";
import MaskedInput from "react-text-mask";
import Input from "../Input";

export default ({
  isFocused,
  maskType,
  mask: regMask,
  ...other
}: {
  isFocused: boolean,
  maskType: string,
  mask: RegExp
}) => {
  let mask = regMask || [/\d/, /\d/, /\d/, /\d/];

  if (maskType && maskType === "phone") {
    mask = [
      "+",
      "7",
      " ",
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
  }

  return (
    <MaskedInput
      {...other}
      mask={mask}
      render={(ref, props) => <Input inputRef={ref} {...props} />}
    />
  );
};
