// @flow

import React, { Component } from "react";
import "./style.css";
import frodo from "./frodo.jpg";
import Button from "../Button";

type Props = {};

class Error extends Component<Props> {
  handleClick = () => {
    window.location.href = window.location.href;
  };

  render() {
    return (
      <div className="error">
        <div className="error__inner">
          <h2 className="error__header">Ой!</h2>
          <div className="error__description">
            Опять форму
            <br />
            заново заполнять...
          </div>
          <div className="error__button">
            <Button primary onClick={this.handleClick}>
              Заполнить заново
            </Button>
          </div>
        </div>
        <div className="error__image_wrapper">
          <img src={frodo} alt="" className="error__image" />
        </div>
      </div>
    );
  }
}

export default Error;
