// @flow

import React, { Component } from "react";
import Input from "../Input";
import classNames from "classnames";
import "./style.css";

const formatted = string =>
  string.replace(/\s/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");

type Props = {
  value: string,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  onBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => void
};

type State = {
  value: string,
  isFocused: boolean
};

class PriceInput extends Component<Props, State> {
  state = {
    value: "",
    isFocused: false
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (e.target.value === "" || /^[0-9\b]+$/.test(e.target.value)) {
      this.setState({ value: e.target.value });
      this.props.onChange(e);
    } else {
      e.preventDefault();
      return false;
    }
  };

  handleFocus = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.setState({ isFocused: true });
    this.props.onFocus(e);
  };

  handleBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.setState({ isFocused: false });
    this.props.onBlur(e);
  };

  handleValue = () => {
    // if (!this.state.isFocused) {
    //   return formatted(this.state.value);
    // }

    return this.state.value;
  };

  handleOnKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-|e/.test(keyValue)) {
      e.preventDefault();
    }
  };

  render() {
    const classes = classNames("price-input__value", {
      "price-input__value--blured": !this.state.isFocused
    });

    return (
      <div className="price-input form__input">
        <div className="price-input__hint">
          <span className={classes}>
            {this.state.isFocused
              ? this.state.value
              : formatted(this.state.value || "")}
          </span>
          {this.state.value || this.state.isFocused ? (
            <span
              className={classNames("price-input__currency", {
                "price-input__currency--focused": this.state.isFocused
              })}
            >
              &nbsp;₽
            </span>
          ) : null}
        </div>
        <Input
          type="text"
          {...this.props}
          style={!this.state.isFocused ? { color: "transparent" } : null}
          value={this.handleValue()}
          onKeyPress={this.handleOnKeyPress}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }
}

export default PriceInput;
