// @flow

import React from "react";
import { hydrate, render } from "react-dom";
import "reset-css";
import "react-app-polyfill/ie9";
import "./index.css";
import App from "./App";

const rootElement =
  document.getElementById("root") || document.createElement("div");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
