// @flow

export const getParameterByName = (name: string) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  if (results === null) {
    return "";
  } else {
    let result;
    let url = results[1].replace(/\+/g, " ");
    try {
      result = decodeURIComponent(url);
    } catch (e) {
      if (e instanceof URIError) {
        result = decodeURIComponent(
          url.replace(/\%$/, "").replace(/\%\d$/, "")
        );
      }
    }

    return result;
  }
};

const outerHeight = el => {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
};

export const changeHeight = function() {
  const po = getParameterByName("po");
  let obj = null;

  if (po) {
    obj = {
      name: "change_height",
      value: outerHeight(document.getElementsByTagName("body")[0])
    };
    try {
      window.parent.postMessage(JSON.stringify(obj), po);
    } catch (e) {}
  }
  obj = null;
};
