//@flow
import React, { Component } from "react";
import Fingerprint2 from "fingerprintjs2";
import "./App.css";
import MainForm from "./steps/MainForm";
import EmailSubmit from "./steps/EmailSubmit";
import ExtendedForm from "./steps/ExtendedForm";
import ThankyouPage from "./steps/ThankyouPage";
import Progressbar from "./components/Progressbar";
import Header from "./components/Header";
import { getParameterByName, changeHeight } from "./utils/window";
import {
  EMAIL_STEP,
  EXTENDED_STEP,
  MAIN_STEP,
  THANK_YOU_STEP
} from "./utils/consts";
import ErrorBoundary from "./ErrorBoundary";
import api from "./api";
import type { MainColor, MainData, UserComponents } from "./types/app";

const colors = {
  yellow: {
    default: "#ffdf00",
    lighten: "rgba(255, 223, 0, 0.8)",
    disabled: "rgba(255, 223, 0, 0.65)"
  },
  blue1: {
    default: "#4185f4",
    lighten: "rgba(65, 133, 244, 0.8)",
    disabled: "rgba(65, 133, 244, 0.65)"
  },
  blue2: {
    default: "#0e86d6",
    lighten: "rgba(14, 134, 214, 0.8)",
    disabled: "rgba(14, 134, 214, 0.65)"
  },
  blue3: {
    default: "#2481f3",
    lighten: "rgba(36, 129, 243, 0.8)",
    disabled: "rgba(36, 129, 243, 0.65)"
  },
  green1: {
    default: "#00d47f",
    lighten: "rgba(0, 212, 127, 0.8)",
    disabled: "rgba(0, 212, 127, 0.65)"
  },
  green2: {
    default: "#00c464",
    lighten: "rgba(0, 196, 100, 0.8)",
    disabled: "rgba(0, 196, 100, 0.65)"
  },
  green3: {
    default: "#01c785",
    lighten: "rgba(1, 199, 133, 0.8)",
    disabled: "rgba(1, 199, 133, 0.65)"
  },
  purple: {
    default: "#9a78fb",
    lighten: "rgba(154, 120, 251, 0.8)",
    disabled: "rgba(154, 120, 251, 0.65)"
  },
  orange: {
    default: "#f96d4d",
    lighten: "rgba(249, 109, 77, 0.8)",
    disabled: "rgba(249, 109, 77, 0.65)"
  },
  default: {
    default: "#2a87d2",
    lighten: "rgba(42, 135, 210, 0.8)",
    disabled: "rgba(42, 135, 210, 0.65)"
  }
};

const steps = {
  [MAIN_STEP]: MainForm,
  [EMAIL_STEP]: EmailSubmit,
  [EXTENDED_STEP]: ExtendedForm,
  [THANK_YOU_STEP]: ThankyouPage
};

const headerIsVisible = step =>
  step === MAIN_STEP || step === EMAIL_STEP || step === EXTENDED_STEP;

const getMetrics = () => {
  if (!!window.yaCounter50695276) {
    return window.yaCounter50695276;
  } else {
    return {
      reachGoal: _goal => {},
      getClientID: () => {}
    };
  }
};

const reachGoal = (metrics: { reachGoal: Function }, goal: string) => {
  try {
    metrics.reachGoal(goal);
  } catch (e) {}
};

const yandexClientId = (metrics: { getClientID: Function }) => {
  try {
    return metrics.getClientID();
  } catch (e) {
    return "";
  }
};

const isMobileRegexp = (): boolean =>
  (function(a) {
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    );
  })(navigator.userAgent || navigator.vendor || window.opera);

type Props = {};

type State = {
  step: number,
  amount: string,
  uuid: string,
  mainData: MainData,
  userHash: string,
  userComponents: UserComponents,
  referrer: string,
  parentOrigin: string,
  mainColor: string | MainColor,
  webmasterId: null | string,
  userAgent: string,
  yandexClientId: string
};

class App extends Component<Props, State> {
  state = {
    step: MAIN_STEP,
    amount: "0",
    uuid: "",
    mainData: {},
    userHash: "",
    userComponents: {},
    referrer: "",
    parentOrigin: "",
    mainColor: "default",
    webmasterId: null,
    userAgent: navigator.userAgent,
    yandexClientId: ""
  };

  timeout: TimeoutID;

  componentDidMount() {
    const cParam: string = getParameterByName("c") || "default";
    const mainColor = colors.hasOwnProperty(cParam)
      ? colors[cParam]
      : colors.default;
    const currentYandexClientId = yandexClientId(getMetrics());

    Fingerprint2.getV18({}, (userHash, userComponents) => {
      this.setState({
        userHash,
        userComponents,
        referrer: getParameterByName("ref"),
        parentOrigin: getParameterByName("po"),
        mainColor,
        webmasterId: getParameterByName("w"),
        yandexClientId: currentYandexClientId
      });
    });

    window.addEventListener("DOMContentLoaded", changeHeight);
    window.addEventListener("resize", changeHeight);

    const bodyStyle = document.getElementsByTagName("body")[0].style;

    bodyStyle.setProperty("--main-color", mainColor.default);
    bodyStyle.setProperty("--main-color--lighten", mainColor.lighten);
    bodyStyle.setProperty("--main-color--disabled", mainColor.disabled);

    setTimeout(() => {
      const newClientId = yandexClientId(getMetrics());
      if (currentYandexClientId !== newClientId) {
        this.setState({ yandexClientId: newClientId });
      }
    }, 1500);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.userHash !== this.state.userHash) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      // this.timeout = setTimeout(() => {
      //   api.statistics.post({
      //     yandex_client_id: yandexClientId(getMetrics()),
      //     fingerprint: this.state.userHash,
      //     user_agent: this.state.userAgent,
      //     website: this.state.parentOrigin,
      //     webmaster_id: this.state.webmasterId,
      //     referrer: this.state.referrer
      //   });
      // }, 2000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("DOMContentLoaded", changeHeight, true);
    window.removeEventListener("resize", changeHeight, true);
  }

  handleChangeStep = (nextStep: number) => {
    this.setState({ step: nextStep }, () => {
      this.handleChangeHeight();
    });
  };

  handleChangeAmount = (nextAmount: string) => {
    this.setState({ amount: nextAmount }, () => {
      this.handleChangeHeight();
    });
  };

  handleChangeHeight = () => {
    changeHeight();
  };

  handleSubmitForm = (uuid: string, mainData: MainData) =>
    this.setState({ uuid, mainData }, () => {
      this.handleChangeHeight();
    });

  handleReachGoal = (goal: string) => reachGoal(getMetrics(), goal);

  render() {
    const Step = steps[this.state.step];

    const isHeader = headerIsVisible(this.state.step);
    const {
      userHash,
      userComponents,
      referrer,
      parentOrigin,
      webmasterId,
      yandexClientId
    } = this.state;

    return (
      <div className="App">
        <div className="lcform">
          {isHeader && <Header step={this.state.step} />}
          <ErrorBoundary>
            <Progressbar amount={this.state.amount} step={this.state.step} />

            <Step
              onChangeStep={this.handleChangeStep}
              onChangeAmount={this.handleChangeAmount}
              onChangeHeight={this.handleChangeHeight}
              onSubmitForm={this.handleSubmitForm}
              onReachGoal={this.handleReachGoal}
              uuid={this.state.uuid}
              mainData={this.state.mainData}
              headerIsVisible={isHeader}
              yandexClientId={yandexClientId}
              userHash={userHash}
              userComponents={userComponents}
              referrer={referrer}
              parentOrigin={parentOrigin}
              webmasterId={webmasterId}
              mobile={isMobileRegexp()}
              isMobile={isMobileRegexp}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default App;
