// @flow

import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Field, Form } from "react-final-form";
import Checkbox from "../../components/Checkbox/index";
import Button from "../../components/Button/index";
import InputGroup from "../../components/InputGroup/index";
import Input from "../../components/Input/index";
import AutocompleteInput from "../../components/AutocompleteInput";
import MaskedInput from "../../components/MaskedInput/index";
import PriceInput from "../../components/PriceInput/index";
import api from "../../api/index";
import "./style.css";
import { EXTENDED_STEP } from "../../utils/consts";
import { saveUuid } from "../../utils/uuid";
import { errorMessage, isError } from "../../utils/form";
import Validator, {
  isEmail,
  isNumber,
  isRequired
} from "../../utils/Validator";

import type { UserComponents, MainData } from "../../types/app";

const stringFormatter = str => {
  return str.replace(/\s+/g, "");
};

type Props = {
  userHash: string,
  isMobile: () => boolean,
  parentOrigin: string,
  userComponents: UserComponents[],
  referrer: string,
  webmasterId: string,
  headerIsVisible: boolean,
  onChangeHeight: Function,
  yandexClientId: string,
  onChangeStep: (EMAIL_STEP: number) => void,
  onChangeAmount: (amount: string) => void,
  onSubmitForm: (uuid: string, values: MainData) => void,
  onReachGoal: (goal: string) => void
};

class MainForm extends Component<Props> {
  getParts = (value: ?string) => {
    if (!value) {
      return ["surname"];
    }

    const count = value.split(" ").length;

    if (count === 1) {
      return ["surname"];
    } else if (count === 2) {
      return ["surname", "name"];
    } else if (count >= 3) {
      return ["surname", "name", "patronymic"];
    }

    return ["surname"];
  };

  fullNameFetchSource = (inputValue: string) =>
    api.suggestions.names(inputValue, this.getParts(inputValue));

  emailFetchSource = (inputValue: string) => api.suggestions.emails(inputValue);

  handleSubmit = async (values: MainData) => {
    try {
      const data = await api.main.create(values, {
        userHash: this.props.userHash,
        userComponents: this.props.userComponents,
        parentOrigin: this.props.parentOrigin,
        referrer: this.props.referrer,
        webmasterId: this.props.webmasterId,
        yandexClientId: this.props.yandexClientId
      });

      // this.props.onChangeStep(EMAIL_STEP);
      this.props.onChangeStep(EXTENDED_STEP);

      this.props.onSubmitForm(data.uuid, values);
      this.props.onReachGoal("step1");
      saveUuid(data.uuid);
    } catch (error) {
      return error.response.data.errors;
    }
  };

  handleValidate = (values: { amount: string }) => {
    if (values.amount) {
      this.props.onChangeAmount(values.amount);
    }

    const validator = Validator.create(values)
      .validate(
        {
          email: "Укажите электронную почту",
          amount: "Укажите желаемый кредитный лимит",
          full_name: "Введите фамилию, имя и отчество через пробел",
          phone: "Укажите мобильный телефон",
          tou_agreement: "Согласитесь с условиями передачи информации"
        },
        isRequired
      )
      .validate(
        {
          email: "Укажите почту правильно, например: user@mail.ru"
        },
        isEmail
      )
      .validate(
        { full_name: "Введите фамилию, имя и отчество через пробел" },
        value =>
          value
            .toString()
            .trim()
            .split(" ").length > 1
      )
      .validate(
        { amount: "Укажите кредитный лимит числом, например: 10000" },
        value => isNumber(stringFormatter(value).toString())
      )
      .validate(
        { amount: "Укажите кредитный лимит от 1 тыс. до 5 млн." },
        value => {
          const amount = Number(stringFormatter(value || "").toString());

          return amount >= 1000 && amount <= 5000000;
        }
      )
      .validate(
        { phone: "Укажите телефон, например: +7 (945) 123-1234" },
        value => !!value.match(/^\+7 \(9\d{2}\) \d{3}-\d{4}$/)
      );

    return validator.errors;
  };

  render() {
    const classes = classNames("lcform__form", "form", "form--container", {
      "lcform__form--with-header": this.props.headerIsVisible
    });

    return (
      <Fragment>
        <div className={classes} data-role="page">
          <Form
            onSubmit={this.handleSubmit}
            validate={this.handleValidate}
            initialValues={{ amount: "10000" }}
            render={({ handleSubmit, submitting }) => (
              <form
                className="form__step step"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Field name="amount">
                  {({ input, meta }) => (
                    <InputGroup
                      component={PriceInput}
                      valid={meta.touched && meta.valid}
                      error={isError(meta) ? errorMessage(meta) : null}
                      label="Желаемый кредитный лимит"
                      id="amount"
                      pattern="[0-9]*"
                      type={this.props.isMobile() ? "number" : "text"}
                      inputMode={"numeric"}
                      {...input}
                    />
                  )}
                </Field>

                <Field name="full_name">
                  {({ input, meta }) => (
                    <InputGroup
                      component={AutocompleteInput}
                      valid={meta.touched && meta.valid}
                      error={isError(meta) ? errorMessage(meta) : null}
                      label="Фамилия, имя и отчество"
                      placeholder="Иванов Петр Иванович"
                      isOnlyRussian
                      id="full_name"
                      fetchSource={this.fullNameFetchSource}
                      {...input}
                    />
                  )}
                </Field>

                <div className="step__row row">
                  <Field name="phone">
                    {({ input, meta }) => (
                      <InputGroup
                        component={MaskedInput}
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        label="Мобильный телефон"
                        placeholder="+7 (945) 123-1234"
                        id="phone"
                        maskType="phone"
                        inputMode={"tel"}
                        type={this.props.isMobile() ? "tel" : "text"}
                        {...input}
                      />
                    )}
                  </Field>

                  <Field name="email">
                    {({ input, meta }) => (
                      <InputGroup
                        component={AutocompleteInput}
                        valid={meta.touched && meta.valid}
                        error={isError(meta) ? errorMessage(meta) : null}
                        label="Электронная почта"
                        id="email"
                        maxSuggestions={3}
                        placeholder="mail@example.com"
                        fetchSource={this.emailFetchSource}
                        inputMode={"email"}
                        type={this.props.isMobile() ? "email" : "text"}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="step__row row row--footer">
                  <div className="input__group group group--checkbox">
                    <Field type="checkbox" name="tou_agreement">
                      {({ input, meta }) => (
                        <Fragment>
                          <Checkbox
                            id="tou_agreement"
                            {...input}
                            valid={meta.touched && meta.valid}
                            error={isError(meta) ? errorMessage(meta) : null}
                          />
                          <label
                            className={`group__label ${
                              isError(meta) ? "group__label--error" : ""
                            }`}
                            htmlFor="tou_agreement"
                          >
                            Я принимаю{" "}
                            <a
                              href={`${this.props.parentOrigin}/policy`}
                              rel="noreferrer noopener"
                              target="_blank"
                            >
                              условия передачи информации
                            </a>
                          </label>
                        </Fragment>
                      )}
                    </Field>
                  </div>
                  <div className="input__group group group--button">
                    <Button success submit disabled={submitting}>
                      Далее
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Fragment>
    );
  }
}

export default MainForm;
