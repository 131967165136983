// @flow

export const saveUuid = (uuid: string) => {
  // localStorage.setItem("uuid", uuid);
  // localStorage.setItem("uuid_date", Date.now());
  return false;
};

export const getUuid = () => {
  // return localStorage.getItem("uuid");
  return false;
};

export const isUuid = () => {
  // const isUuid = !!getUuid();
  // const uuidDate = localStorage.getItem("uuid_date");
  //
  // return isUuid && uuidDate && (Date.now() - uuidDate) / 1000 / 60 < 15;
  return false;
};

export const deleteUuid = () => {
  // localStorage.removeItem("uuid");
  return false;
};
