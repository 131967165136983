// @flow

import React, { Component } from "react";
import type { Node } from "react";

import Error from "./components/Error";
import bugsnagClient from "./utils/bugsnag";

const formatComponentStack = str => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = "";
  for (let line = 0, len = lines.length; line < len; line += 1) {
    if (lines[line].length) ret += `${ret.length ? "\n" : ""}${lines[line]}`;
  }
  return ret;
};

type Props = {
  children: Node
};

type State = {
  hasError: boolean
};

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false
  };

  componentDidCatch(error: { name: string, message: string }, info: Object) {
    this.setState({
      hasError: true
    });

    const { BugsnagReport } = bugsnagClient;

    const handledState = {
      severity: "error",
      unhandled: true,
      severityReason: { type: "unhandledException" }
    };

    const report = new BugsnagReport(
      error.name,
      error.message,
      BugsnagReport.getStacktrace(error),
      handledState
    );

    report.updateMetaData("react", {
      ...info,
      componentStack:
        info && info.componentStack
          ? formatComponentStack(info.componentStack)
          : null
    });

    bugsnagClient.notify(report);
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
