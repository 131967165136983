// @flow

import React, { Component } from "react";
import classNames from "classnames";
import "./style.css";

const blurModifier = "input--hasValue";
const focusModifier = "input--hasFocus";

type Props = {
  id: string,
  error?: ?string,
  label: string,
  valid?: boolean,
  value: string,
  component: Function,
  onBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => void,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void
};

type State = {
  isFocused: boolean
};

class Group extends Component<Props, State> {
  constructor() {
    super();

    // this.state = {
    //   blur: false,
    //   focus: false,
    // };
    // this.handleblur = this.handleBlur.bind(this);
    // this.handlefocus = this.handleFocus.bind(this);
  }
  // handleBlur(e) {
  //   let input = e.target;
  //   // let inputParent = input.parentNode;
  //
  //   this.setState({
  //     inputfocus: this.state.focus
  //   });
  //
  //   if (input.value !== "") {
  //     this.setState({
  //       inputblur: !this.state.blur
  //     })
  //   } else {
  //     this.setState({
  //       inputblur: this.state.blur
  //     })
  //   }
  //   console.log('bar');
  // };
  // handleFocus(e) {
  //   let input = e.target;
  //   let inputParent = input.parentNode;
  //   if (input === document.activeElement) {
  //     this.state.focus = true;
  //   }
  // };

  state = {
    isFocused: false
  };

  handleFocusChange = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.props.onFocus(e);
    this.setState({ isFocused: true });
  };

  handleBlurChange = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.props.onBlur(e);
    this.setState({ isFocused: false });
  };

  handleChange = (value: SyntheticInputEvent<HTMLInputElement>) => {
    return this.props.onChange(value);
  };

  render() {
    const {
      id,
      error,
      label,
      valid,
      value,
      component,
      onFocus,
      onBlur,
      ...other
    } = this.props;

    const { isFocused } = this.state;
    const Comp = component;
    const classes = classNames("input__group", "group", {
      [focusModifier]: isFocused,
      [blurModifier]: !!value,
      "input--empty": !value,
      "group--error": !!error
    });
    const labelClasses = classNames("group__label", {
      "group__label--error": !!error
    });

    if (!component) {
      console.warn("Не задан компонент для группы");
      return "";
    }

    return (
      <div className={classes}>
        {!!component && (
          <Comp
            {...other}
            valid={valid}
            value={value}
            onChange={this.handleChange}
            onFocus={this.handleFocusChange}
            onBlur={this.handleBlurChange}
            isFocused={this.state.isFocused}
            id={id}
          />
        )}
        <label className={labelClasses} htmlFor={id}>
          {!!error ? error : label}
        </label>
      </div>
    );
  }
}

export default Group;
