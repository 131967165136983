// @flow

import React, { Component } from "react";
import classNames from "classnames";
import "./style.css";

type Props = {
  className: string,
  valid?: boolean,
  error: ?string
};

class RadioInput extends Component<Props> {
  render() {
    const { className, valid, error, ...other } = this.props;
    const classes = classNames("form__input", "input", "input--radio", {
      [className]: true
    });

    return <input type="radio" className={classes} {...other} />;
  }

  static defaultProps = {
    className: "radio-group--default"
  };
}

export default RadioInput;
